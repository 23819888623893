import React, { useEffect, useState } from 'react';
import WalletConnect from './components/WalletConnect';
import { ethers } from 'ethers';
import './App.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import contractABI from './abi/contractABI.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
// import contractByteCode from './abi/bytecode.js'

const contractAddress = '0x331c65437Ed331183184825fc0D6f05b96D9c780'

function App() {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [copied, setCopied] = useState(false);


  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [deployedCA, setDeployedCA] = useState(null);

  const [contractList, setContractList] = useState(0);

  const [showError, setShowError] = useState(false)
  const [showList, setShowList] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [myContract, setMyContract] = useState([])


  //基本資訊
  const [name, setName] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [supply, setSupply] = useState(0);
  // const [decimal, setDecimal] = useState(0);

  const handleAccountChange = (value) => {
    setDefaultAccount(value);
  }
  const deployFee = ethers.utils.parseEther("0.03")

  const updateContract = () => {
    if (JSON.parse(localStorage.getItem('contractArray')) !== null)
      setMyContract(JSON.parse(localStorage.getItem('contractArray')));
    console.log(JSON.parse(localStorage.getItem('contractArray')))
  }

  const updateEthers = async () => {
    try {
      let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);

      let tempSigner = tempProvider.getSigner();
      setSigner(tempSigner);

      let tempContract = new ethers.Contract(contractAddress, contractABI, tempSigner)
      setContract(tempContract);

      let tempList = await tempContract.getDeployedTokens()
      setContractList(tempList.length);
    } catch (error) {
      console.log(error)
    }
  }

  const checkParams = () => {
    if (name == null || symbol == null || supply == null || supply < 1) {
      alert("Please Fill In The Valid Parameters")
      return;
    }
    deployContract()
    setIsLoading(true)
    handleOpenModal()
  }

  const deployContract = async () => {
    console.log("Deploying Contract");
    const realSupply = ethers.utils.parseEther(supply);

    const args = [name, symbol, realSupply];
    try {
      let result = await contract.createToken(...args
        , {
          value: deployFee
        }
      );
      // console.log(result.hash)
      getLog(result.hash)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setShowError(true)
    }
  }

  useEffect(() => {
    updateEthers()
  }, [defaultAccount])

  const texts = [
    {
      title: 'Name',
      placeholder: 'Contract Name',
      function: (e) => setName(e.target.value),
      type: 'text',
    },
    {
      title: 'Symbol',
      placeholder: 'Contract Symbol',
      function: (e) => setSymbol(e.target.value),
      type: 'text',
    },
    {
      title: 'Supply',
      placeholder: 'Contract Supply',
      function: (e) => setSupply(e.target.value),
      type: 'number',
    },
  ]

  const getLog = async (value) => {
    const transaction = await provider.getTransactionReceipt(value);
    const topic = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";
    const zeroAddress = "0x0000000000000000000000000000000000000000000000000000000000000000";
    transaction.logs.map((data, index) => {
      if (data.topics[0] != topic) return;
      if (data.topics[1] != zeroAddress) return;
      setDeployedCA(transaction.logs[index].address)
      myContract.push(transaction.logs[index].address)
      localStorage.setItem('contractArray', JSON.stringify(myContract));
      setIsLoading(false)
      setContractList(prev => prev + 1)
    })
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    if (isLoading) return;
    setShowModal(false);
    setShowError(false);
  };

  const handleListOpen = () => {
    setShowList(true);
  };

  const handleListClose = () => {
    setShowList(false);
  };

  const copyCA = (value) => {
    if (value == null) {
      alert("No Contract")
      return;
    }
    try {
      setCopied(true)
      alert(`CA : ${value} copied`)
    } catch (error) {
      console.log(error);
    }
  }


  //翻頁設計
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end index of the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  useEffect(() => {
    if (myContract == [])
      updateContract()
    if (myContract != [])
      setCurrentData(myContract.slice(startIndex, endIndex))
  }, [startIndex, myContract])

  return (
    <div className="App" style={{
      width: '100vw', height: '100vh',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <div style={{
        padding: '30px',
        border: '1px solid lightblue',
        borderRadius: '20px'
      }}>
        <div className='WalletBtnContainer' style={{
          // display: 'flex', flexDirection: 'row',
          position: 'fixed', top: '20px', right: '20px'
        }}>
          <WalletConnect defaultAccountChange={handleAccountChange} />
        </div>
        <div className='ContentContainer' style={{
          width: '80vw',
          height: '60vh',
          borderRadius: '20px',
          display: 'flex', flexDirection: 'column',
          alignItems: 'center', justifyContent: 'center'
        }}>
          <h3>
            zkSync Token Creator <br />
            <span style={{ color: 'red' }}>0.03 ETH</span>
          </h3>
          <div style={{
            color: 'deepskyblue', fontSize: '30px', fontWeight: 'bold',
          }}
          >
            {
              texts.map((text, index) => {
                return (

                  <div className='row'
                    style={{
                      display: 'flex', flexDirection: 'row',
                      paddingTop: '5vh',
                      justifyContent: 'space-between'
                    }}
                    key={index}
                  >
                    <span>
                      {text.title}
                    </span>
                    <input placeholder={text.placeholder}
                      style={{
                        borderRadius: '10px', height: '40px',
                        marginLeft: '20px', paddingLeft: '20px',
                        border: '2px solid lightblue',
                        outlineColor: 'red',
                      }}
                      onChange={text.function}
                      type={text.type}
                    />
                  </div>
                )
              })
            }
          </div>

          <button
            onClick={() => {
              checkParams()
            }}
            style={{
              marginTop: '5vh',
            }}
          >
            Deploy 0 Tax Contract
          </button>

          {/* <button
            onClick={handleListOpen}
            style={{
              marginTop: '5vh',
            }}
          >
            See My Contracts
          </button> */}
        </div>
      </div>
      <p>
        Platform Contract Total : {contractList}
      </p>
      {
        showModal &&
        (<Modal
          onClose={handleCloseModal}
          loading={isLoading}
          loadingText="Deploying..."
          showError={showError}
        >
          <h2>Contract Address : </h2>
          <p style={{ fontSize: '12px' }}>{deployedCA}</p>
          <CopyToClipboard
            text={deployedCA}
            onCopy={() => copyCA(deployedCA)}
          >
            <button style={buttonStyle} onClick={() => setIsLoading(false)}>
              Copy CA
            </button>
          </CopyToClipboard>
        </Modal>)
      }
      {
        showList &&
        <ListModal
          onClose={handleListClose}
        >
          Your Contract List <br />
          {
            myContract.length !== 0 &&
            currentData.map((contract, index) => {
              return (
                <div key={index} >
                  <p style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}>

                    <CopyToClipboard
                      text={contract}
                      onCopy={() => copyCA(contract)}
                    >
                      <span style={buttonStyle} onClick={() => setIsLoading(false)}>
                        <FontAwesomeIcon icon={faCopy} />
                      </span>
                    </CopyToClipboard>

                    <span style={{
                      ...buttonStyle,
                      wordWrap: 'break-word',
                      fontSize: '12px'
                    }}>{contract}</span>
                  </p>
                </div>
              )
            })
          }
          {
            myContract.length == 0 &&
            <p>You don't have any contract yet!!</p>
          }
          <div style={{
            position: 'absolute', bottom: '20vh',
            width: "80%",
            maxWidth: "600px",
          }}>

            <button onClick={() => {
              if (currentPage > 1)
                setCurrentPage(prev => prev - 1)
              else
                return alert('No more pages')
            }}> {"<<"} </button>
            <span style={{ padding: '0px 20px' }}>{currentPage}</span>
            <button onClick={() => {
              if (endIndex < myContract.length)
                setCurrentPage(prev => prev + 1)
              else
                return alert('No more pages')
            }}> {">>"}</button>
          </div>
        </ListModal>
      }
    </div>
  );
}
const modalStyle = {
  position: "fixed",
  zIndex: 1,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.4)"
};

const modalContentStyle = {
  borderRadius: '40px',
  backgroundColor: "#fefefe",
  margin: "20vh auto",
  padding: "20px",
  border: "1px solid #888",
  width: "80%",
  maxWidth: "600px",
  height: '60vh',
};

const closeStyle = {
  color: "#aaa",
  float: "right",
  fontSize: "28px",
  fontWeight: "bold",
  position: "relative",
  zIndex: "2",
};

const buttonStyle = {
  position: "relative",
  zIndex: "2",
}

export const Modal = (props) => {
  const errorMessage = "Failed to deploy, you need to confirm the transaction";
  const { onClose, onCloseLoading, loading, loadingText, showError, children } = props;
  console.log(loading);
  return (
    <div
      style={modalStyle}
    >
      {
        loading &&
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">{loadingText}</p>
        </div>
      }
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={onClose}>&times;</span>
        {children}
        {
          showError &&
          <p>{errorMessage}</p>
        }
      </div>
    </div>
  );
};

export const ListModal = (props) => {
  const { onClose, children } = props;
  return (
    <div
      style={modalStyle}
    >
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};



export default App;
